* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  background-color: #081118 !important;
}

a {
  color: inherit;
  text-decoration: none;
}

.mantine-Carousel-indicator {
  width: 5px !important;
}

.mantine-Autocomplete-input {
  background-color: rgba(118, 118, 128, 0.2) !important;
  border-radius: 10px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  outline: none;
  margin: 5px 1px 3px 0px;
}

.mantine-Autocomplete-input:focus {
  box-shadow: 0px 2px 25px rgba(123, 57, 182, 0.3);
  margin: 5px 1px 3px 0px;
  border: 1px solid #7b39b6;
}

.login-input .mantine-TextInput-input {
  background: rgba(232, 231, 234, 0.12);
  backdrop-filter: blur(100px);
  border-radius: 10px;
}

.login-input .mantine-TextInput-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(232, 231, 234, 0.3);
  opacity: 1; /* Firefox */
}

.tooltip {
  position: absolute;
  padding: 10px;
  background: #f9f9f9;
  border: 1px solid #c0c0c0;
  border-radius: 5px;
  pointer-events: none;
}

.multiline-ellipsis {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; /* start showing ellipsis when 3rd line is reached */
  white-space: pre-wrap; /* let the text wrap preserving spaces */
}

.skewed-button {
  position: relative;
  padding: 10px 20px 10px 10px;
  position: relative;
}

.skewed-button:after {
  content: ' ';
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  transform-origin: bottom left;
  -ms-transform: skew(-30deg, 0deg);
  -webkit-transform: skew(-30deg, 0deg);
  transform: skew(-30deg, 0deg);
}
